import { useEffect } from "react"
import styled from "styled-components"

import { LinearProgressBar } from "src/components/ProgressBar/LinearProgressBar"
import { breakpoint } from "src/constants/breakpoints"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { TextButton } from "src/ui/Button/TextButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { BaseWizard } from "src/ui/Wizard/BaseWizard"
import { TFullscreenWizard, TWizard } from "src/ui/Wizard/wizardTypes"
import { getNumberOfSteps } from "src/ui/Wizard/wizardUtils"
import { isString } from "src/utils/genericUtil"

export function FullscreenWizard({
  currentStep,
  steps,
  title,
  metaTitle,
  onClose,
  onNext,
  onBack,
}: TFullscreenWizard) {
  const _metaTitle = !!metaTitle
    ? metaTitle
    : isString(title)
      ? title
      : undefined

  const _showProgress = !steps[currentStep]?.hideProgress

  // Set scroll position to top between step changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  return (
    <>
      <DocumentHead title={_metaTitle} />

      <BaseWizard
        steps={steps}
        currentStep={currentStep}
        onNext={onNext}
        onBack={onBack}
        TopContent={<TopContent title={title} onClose={onClose} />}
        ProgressBar={
          _showProgress ? (
            <LinearProgressBar
              segments={[
                {
                  value: getProgressBarValue({ currentStep, steps }),
                  type: "primary",
                },
              ]}
              showRoundedBorders={false}
              height="S"
            />
          ) : undefined
        }
        HeaderBox={StickyTop}
        ContentBox={ContentBox}
      />
    </>
  )
}

function getProgressBarValue({
  currentStep,
  steps,
}: Pick<TWizard, "currentStep" | "steps">) {
  const visibleNbrSteps = getNumberOfSteps(steps)
  return (currentStep / visibleNbrSteps) * 100
}

function TopContent({
  title,
  onClose,
}: {
  title?: React.ReactNode
  onClose?: () => void
}) {
  const { langKeys, t } = useTranslate()

  return (
    <TopBox>
      <MText variant="subtitle">{title}</MText>
      {onClose && (
        <TextButton onClick={onClose}>{t(langKeys.close)}</TextButton>
      )}
    </TopBox>
  )
}

const ContentBox = styled.div`
  max-width: 67ch;
  margin: ${spacing.XL} auto;
  padding: 0 ${spacing.M};
  display: grid;
  gap: ${spacing.XL2};

  @media (${breakpoint.mediumUp}) {
    margin: 9vh auto;
  }
`

const StickyTop = styled.div`
  background: white;
  position: sticky;
  top: 0;
  z-index: 100;
`

const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.L};
`
